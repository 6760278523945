/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: Arsenal;
  src: url(assets/font/Arsenal-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Arsenal;
  src: url(assets/font/Arsenal-Bold.ttf) format("truetype");
  font-weight: bold;
}

body{
    font-family: Arsenal
}
a{
    color:#be365a!important;
    cursor: pointer;
}
.page{
    padding-bottom: 70px;
    min-height: 500px;
}

.logo img {
    height: 6em;
}
.full_width {
    position: relative;
    height: 100vh;
    width: 100%;
}
.center_v {
    position: absolute;
    width: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(50%);
}
.centerItems{
    display: flex;
    align-items: center;
}
/*logged in user*/

.loginHeader {
    background: #adadad36;
}
.user, .lang {
    font-size: 25px;
    
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.language{
    background: none;
    border: 0;
    background: #bb506c;
    color: white;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 15px;
    padding: 5px;
    margin: 5px;
}
.userName {
    visibility: hidden;
}
.userIcon {
    cursor: pointer;
}
.user:hover .userName {
    visibility: visible;
}
.userSignOut {
    cursor: pointer;
}
.userIcon, .userSignOut {
    padding: 0px 10px;
}
.title{
	padding-top: 20px;
	padding-bottom: 0px;
	font-size: 20px;
	text-transform: uppercase;
    color: #be365a;
    border-bottom: 1px solid;
    margin-bottom: 30px;
}
.subtitle{
    font-size: 16px;
    color: #be365a;
    text-transform: uppercase;
    padding: 10px 0px;
}
.group{
    padding-top: 10px;
    padding-bottom: 10px;
}
.group .pad{
    padding: 5px 0px;
}
.req{
	font-size: 12px
}
.mandatory{
    color: red;
}

.mandatory_line{
    display: block;
    border-top: 1px solid red;
}
.notMandatory{
    font-size: 15px;
    text-transform: uppercase;
    color:rgb(190 54 90);
}

.subtext{
    font-size: 9px;
    color:grey;
}
.btn-primary{
    background: #be365a;
    border-color: #be365a;
}
.btn-primary:hover{
    background: #be365a;
    border-color: #be365a;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #545151;
    background-color: #eeeeee;
    border-color: #eeeeee;
}

.btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background: #be365a;
    border-color: #be365a;
    box-shadow: 0 0 0 0.2rem #f2f2f2;
}
.allowReport{
    border: 3px solid #be365a;
    
    padding: 16px;
    text-transform: uppercase;
    font-size: 15px;
    background: #be365a;
    color: white;
    padding-left: 37px;
}
._published{
    margin-bottom: 20px;
}
agm-map {
  height: 500px;
}

.mymap{
    position: relative;
}

.map-container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
 
  z-index: 0;
}

.map-frame {
  border: 1px solid #afadad;
  height: 100%;
}

#map {
  height: 400px;
  
}
#mapHome{
    height: 80vh;

}

#mapStatisticPost{
    height: 300px
}
.map-container{
    position: relative;
}
#mapList{
    height: 400px;
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    border-top: 1px solid #dbdada;
}

.marker-cluster-large {
  background-color: #bb506cb0!important;
}

.marker-cluster-large div {
  background-color: #bb506c!important;
  color: #fff !important;
    font-weight: bold;

}
.marker-cluster-medium {
  background-color: #c1617aa6!important;
}

.marker-cluster-medium div {
  background-color: #c1617a !important;
  color: #fff !important;
  font-weight: bold;
}
.marker-cluster-small {
  background-color: #c87387b8!important;
}

.marker-cluster-small div {
  background-color: #c87387 !important;
  color: #fff !important;
    font-weight: bold;

}
.leaflet-marker-pane img{
    width: 20px!important;
}

#findbox {
    background: #eee;
    border-radius:.125em;
    border:2px solid #1978cf;
    box-shadow: 0 0 8px #999;   
    margin-bottom: 10px;
    padding: 2px 0;
    
    height: 26px;
}
.search-tooltip {
    width: 200px;
}
.leaflet-control-search .search-cancel {
    position: static;
    float: left;
    margin-left: -22px;
}

.list_grid{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    border-top:1px solid #dbdada;
}

.front_list_grid{
    padding-top: 10px;
    padding-bottom: 10px;
    border-top:1px solid #dbdada;
}

.list_grid_title{
    background: #dbdada59;
}
.list-group-item{
    border-top:1px solid #dbdada;
    padding:.75rem 1.95rem;
    border-left:0;
    border-right: 0;
    border-bottom: 0;
}

.list-group-item:hover{
    cursor: pointer;
    background-color: #bb506c!important;
    color:white;
}
.hover{
    cursor: pointer;
    background-color: #eeeeee!important;
    color:#2c3034;
}

.nav {
    position:absolute;
    bottom:0;
    right:0;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    background-color: #f2f2f2;
    border-color: #dee2e6 #f2f2f2 #f2f2f2;

}

.report_main_btn{
    background: #be365a;
    color: white;
    text-transform: uppercase;
    padding: 8px;
    position: relative;
    z-index: -1
}
.mapButtons_box{
    position: absolute;
    right: 0;
    top:0;
    z-index: 3;
    background: #bb506c;
    width: 5em;
    height: 81vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: -1px 0px 2px 1px #69313f;

}
.mapButtons_box .in{
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mapButtons_box .item{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.mapButtons_box .item:hover{
    background-color: #ffffff1a;
}

.mapButtons_box .items_style{
    background-color: white;
    width: 25px;
    height: 25px;
    mask-repeat:no-repeat;
    mask-position:center;
}

.mapButtons_box .text{
    font-size: 15px;
    padding: 6px 0px
}

.filter{
    -webkit-mask-image: url('assets/img/filter-light.svg');
    mask-image: url('assets/img/filter-light.svg');
}


.add_report{
    -webkit-mask-image: url('assets/img/map-marker-plus-light.svg');
    mask-image: url('assets/img/map-marker-plus-light.svg');
}

.chart{
 -webkit-mask-image: url('assets/img/chart-line-light.svg');
 mask-image: url('assets/img/chart-line-light.svg'); 
}

.info{
 -webkit-mask-image: url('assets/img/info-light.svg');
 mask-image: url('assets/img/info-light.svg'); 
}

.addReportButton{
    position: absolute;
    right: 0
}

.addReportButton img{
    position: relative;
    right: -13px;
    bottom: 2px;
}

.filtericon, .mapicon, .charticon, .infoicon{
    position: absolute;
    top: 0;
    bottom:0;
    background-color: white;
    z-index: 2;
    right: -300px;
    width: 350px;
    transition: all 1s ease;
    cursor: pointer;
    color:#bb506c;;
    box-shadow: -1px 1px 3px #bb506c8a
}

.charticon{
    right: -100%;
    width: 95%;

}

.filtericon_filtericon_show, .mapicon_mapicon_show, .infoicon_infoicon_show{
    right: 75px!important;
    
}

.charticon_charticon_show{
    right: 5%!important
}


.filtericon .list-group-hover:hover
{
  
  background:#bb506c0a;
}



.filtericon li::before{
    font-family: FontAwesome;
    top:0;
    left:-5px;
    padding-right:10px;
    content: "\f0da"; 
}

.filtericon li:first-child::before{
    content: "\f0d7";

}
.filtericon li:first-child{

    padding-left: 18px;
    border-top: 0;

}
.filtericon li:last-child{
    border-bottom: 0;
}

.filtericon li {

    border-bottom: 1px solid #bb506c1f;
    border-top:1px solid #bb506c1f;
    border-left: 0;
    border-right: 0;

}

.filtericon ul {
    padding: 0;
}

.footer{

    background-color: #313030;
    color: white;
}

.footer >.container{
    padding: 30px 0px
}

.report_image_box{
    background-size: 50%;
    width: 100%;
    height: 100%;
    min-height: 80px;
    background-repeat: no-repeat;
background-position: center;
}

.report_date{
    position: absolute;
    top: -11px;
    right: 0;
    background: #d9d9d92b;
    padding: 2px;
    z-index: -1;
    border: 1px solid #eeeeee;
}

.report_location{
    color:grey;
    font-size: 13px;
    
}
p.report_location:first-letter{
    text-transform: uppercase;
}
.report_title{
    font-weight: bold;
    width: 78%;
}
.report_data{
    position: relative;
}
.report_static_date{
position: absolute;
    right: 0;
    background: #eeeeee;
}
.report_filter .filter_item{
    padding: 5px;
}

.report_data a{
    color:black!important;
}

.read_more{
    color: #bb506c;
}

.report_filter{
    background: #eeeeee;
    color: #be365a;
}
.form-group .label{
    font-weight: bold;
}

.filterButton, .filterButton div{
    display: inline-block;
    padding: 5px;
} 
.filterButton:hover{
    background: #be365a;
    color:white;
    cursor: pointer;
}
.pub{
    padding: 20px 0px;
    border-bottom: 1px solid #eeeeee;
}
.b:last-child .pub/*, .b:nth-last-child(2) .pub*/{
border: 0;
}
.pub .date, .pub .download{
    text-align: right;
    color: white;
    display: inline-block;
    padding: 10px;
    
}
.pub .date{
    width: 48%
}

.pub .download{
    font-size: 20px;
}

.pub .box{
    
    width: 100%;
    background-color: #bb506cf5;
    
}
.publication_title{
    font-weight: bold;
    text-transform: uppercase;
}
.publication_image{
    background-size: cover;
    background-position:top center;
    background-repeat: no-repeat;
    min-height: 200px;
    
    width: 100%;
    
}

.pub a{
    color:black!important;
}

.blog_image{
    background-size: 100%;
    height: 268px;
    background-position: center;
    background-repeat: no-repeat;
}
.space{
    padding: 20px 0px;
    margin: 20px 0;
}

.blog_post_image{
 width: 50%;
    height: 325px;
    margin: 0 auto;
}

.blog_box{
    text-align: left;
    background: #ffffff;
    box-shadow: 0px 0px 3px #eeeeee;
    padding: 21px;
    margin-bottom: 15px;
    border: 1px solid #eeeeee;
    position: relative;
}
.blog_box a{
    text-decoration: none;
    color:black!important;
}

.blog_box:after{
    content: ".";
    background: url(/assets/img/box-shadows.png) no-repeat 0 -31px;
     width: 214px; 
    width: 100%;
    height: 10px;
    display: block;
    text-indent: -9999px;
    position: absolute;
    bottom: -9px;
    left: 0px;
    background-size: 112%;
}

.blog_date{
    border-top:1px solid #eeeeee;padding-top: 10px;margin-top:10px
}

.blog_title{
    color:#be365a;
    font-weight: bold;
}

.report_array{
    padding: 15px 0px;
    font-weight: bold;
    text-transform: uppercase;
}


.report_row{
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 15px;
}
.q{
    font-weight: bold;
    width: 40%;
    display: inline-block;

}
p.answ{
    display: inline-block;
    padding: 0;
    margin:0;
    width: 60%;
}
p.answ:first-letter {
    text-transform: uppercase;

} 

p.five_rep_title{
    line-height: 1.1;
    width:79%;
}

.last_five .five_rep_title:first-child{
    margin-top:15px;
}

.e404{
font-size: 40px;
    text-transform: uppercase;
    color: #be365a;
    
}
.acceptToSend{
    border: 2px solid #be365a;
    margin:20px 0;
}

.acceptToSend input{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}


/*editor*/

.centerTextImage{
    text-align: center;
}
.article_img{
    border:1px solid #ccc;
}


@media only screen and (min-width: 320px) and (max-width: 480px) {
.blog_box:after{
    content: ".";
    background: url(/assets/img/box-shadows.png) no-repeat 0 -34px;
     width: 214px; 
    width: 100%;
    height: 10px;
    display: block;
    text-indent: -9999px;
    position: absolute;
    bottom: -9px;
    left: 0px;
    background-size: 112%;
}
.addReportButton,.nav{
    position: relative;
}


}

/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
    @media only screen and (min-width: 481px) and (max-width: 768px) {
        
.blog_box:after{
    content: ".";
    background: url(/assets/img/box-shadows.png) no-repeat 0 -19px;
     width: 214px; 
    width: 100%;
    height: 10px;
    display: block;
    text-indent: -9999px;
    position: absolute;
    bottom: -9px;
    left: 0px;
    background-size: 112%;
}
        }


        

@media only screen and (min-width: 769px) and (max-width: 1024px) {
.blog_box:after{
    content: ".";
    background: url(/assets/img/box-shadows.png) no-repeat 0 -17px;
     width: 214px; 
    width: 100%;
    height: 10px;
    display: block;
    text-indent: -9999px;
    position: absolute;
    bottom: -9px;
    left: 0px;
    background-size: 112%;
}
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
.blog_box:after{
    content: ".";
    background: url(/assets/img/box-shadows.png) no-repeat 0 -24px;
     width: 214px; 
    width: 100%;
    height: 10px;
    display: block;
    text-indent: -9999px;
    position: absolute;
    bottom: -9px;
    left: 0px;
    background-size: 112%;
}
}












